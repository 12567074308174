import React from "react";
import { connect } from "react-redux";
import {
  Redirect
} from "react-router-dom";
import { parse } from "query-string";
import jwtDecode from "jwt-decode";
import isEmpty from "lodash/isEmpty";

import { Audio } from 'react-loader-spinner'


import { receiveAccessToken, receiveIdToken } from "../../actions/tokenActions";
import { receiveProfileData } from "../../actions/profileActions";

const AuthPage = ({
  location,
  profile,
  receiveProfile,
  receiveTokens
}) => {

    const { REACT_APP_HOMEPAGE, PUBLIC_URL } = process.env;
    //const homeUri = REACT_APP_HOMEPAGE + "home";
    //const profileUri = REACT_APP_HOMEPAGE + "profile";


    //const homeUri = PUBLIC_URL + "/home";
    //const profileUri = PUBLIC_URL + "/profile";

    const homeUri = "/home";
    const profileUri = "/profile";



    if (isEmpty(profile)) {
        const hash = location.hash;
        const response = parse(hash);





        if (response.error) {
            alert(response.error_description);
            return <Redirect to={homeUri} />
            /*return <Redirect to="/home" />*/

        } else {
            receiveTokens(response.access_token, response.idToken);
            receiveProfile(jwtDecode(response.id_token));
        }
        // Render loader
        /*return <Loader />;*/

        return <Audio
            height="100"
            width="100"
            color='grey'
            ariaLabel='loading'
        />;


    } else {

        /*return <Redirect to="/profile" />*/
        return <Redirect to={profileUri} />
  }
};

const mapStateToProps = state => ({
  profile: state.profile,
  state: state.token.state,
  nonce: state.token.nonce
});

const mapDispatchToProps = dispatch => ({
  receiveProfile: data => dispatch(receiveProfileData(data)),
  receiveTokens: (accessToken, idToken) => {
    dispatch(receiveAccessToken(accessToken));
    dispatch(receiveIdToken(idToken));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthPage);
