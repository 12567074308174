import React, { useState, useEffect, useRef  } from "react";
/*import { render } from "react-dom";*/
import Select from "react-select"
import useFetch from "./useFetch.js";
import DateTimePicker from "react-datetime-picker"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
//import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
//import { TimePicker } from '@mui/x-date-pickers/TimePicker';
//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

//import DateFnsUtils from "@date-io/date-fns"; // import
///*import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";*/
//import {
//    MuiPickersUtilsProvider,
//    DatePicker,
//    TimePicker,
//    DateTimePicker
//} from "@material-ui-pickers";
/*import NativePickers from "./NativePickers.js"*/
//import TextField from '@mui/material/TextField';
//import Stack from '@mui/material/Stack';

//import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//import {
//    DatePicker,
//    TimePicker,
//    DateTimePicker,
//    MuiPickersUtilsProvider,
//} from '@material-ui/pickers';

export default function JobScheduleAdd(props) {

    const selectPostEventsRef = useRef();

    const [interval, setInterval] = useState(5);
    const [uploadFile, setUploadFile] = useState("");
    /*const [firstRunDateTime, setFirstRunDateTime] = useState < Date | null > (null);*/
    /*const [firstRunDateTime, setFirstRunDateTime] = useState("");*/
    const [firstRunDateTime, setFirstRunDateTime] = useState(new Date());
    const [lastRunDateTime, setLastRunDateTime] = useState(new Date());
    const [startRunTime, setStartRunTime] = useState(new Date());
    const [endRunTime, setEndRunTime] = useState(new Date());

    /*const [selectedDate, handleDateChange] = useState(new Date());*/

    /*const [message, setMessage] = useState("");*/

    /*select options*/
    const [selectedJobOption, setSelectedJobOption] = useState([])
    const [selectedDependsJobOption, setSelectedDependsJobOption] = useState([])
    const [selectedSiteOption, setSelectedSiteOption] = useState([])
    const [selectedPreEventOption, setSelectedPreEventOption] = useState([])
    const [selectedPostEventOption, setSelectedPostEventOption] = useState([])

    /*dropdown list values*/
    /*const [jobs, setJobs] = useState(jobArray);*/
    const [jobs, setJobs] = useState([]);
    const [sites, setSites] = useState([]);
    const [dependsOnJobs, setDependsOnJobs] = useState([]);
    const [preEvents, setPreEvents] = useState([])
    const [postEvents, setPostEvents] = useState([])
    const [message, setMessage] = useState("Schedule Added")
    const [displayMessage, setDisplayMessage] = useState(false)


    const { get, post } = useFetch(process.env.REACT_APP_API_ENDPOINT)

    
    //useEffect(() => {
    //    const timeId = setTimeout(() => {
    //        // After 3 seconds set the show value to false
    //        setDisplayMessage(false)
    //    }, 3000)

    //    return () => {
    //        clearTimeout(timeId)
    //    }
    //}, [props.tableChanged]);


    useEffect(() => {
        get("Jobs")
            .then(data => {
                setJobs(data && data.map((job) => ({ value: job.id, label: job.jobName })))
                setDependsOnJobs(data && data.map((job) => ({ value: job.id, label: job.jobName })))
                console.log(data)
            })
            .catch(error => { console.log(error) })

        get("Sites")
            .then(data => {
                setSites(data && data.map((site) => ({ value: site.id, label: site.site1 })))
                console.log(data)
            })
            .catch(error => { console.log(error) })

        get("PreEvents")
            .then(data => {
                setPreEvents(data && data.map((preEvent) => ({ value: preEvent.id, label: preEvent.eventName })))
                console.log(data)
            })

        get("PostEvents")
            .then(data => {
                setPostEvents(data && data.map((postEvent) => ({ value: postEvent.id, label: postEvent.eventName })))
                console.log(data)
            })

    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        console.log("handleSubmit is clicked")

        post("JobSchedules/Create", JSON.stringify({
            JobId: selectedJobOption,
            Interval: interval,
            DependsOnJobId: selectedDependsJobOption,
            Site: selectedSiteOption,
            PreEvents: selectedPreEventOption,
            PostEvents: selectedPostEventOption,
            UploadFile: uploadFile,
            FirstRunDateTime: firstRunDateTime,
            LastRunDateTime: lastRunDateTime,
            StartRunTime: startRunTime,
            EndRunTime: endRunTime
        }))

            .then(data => {
                console.log("returned data")
                setDisplayMessage(true)
                console.log(data)
            })
            .catch(error => { console.log(error) })
            .finally(() => {
                props.onTableChanged(true)

                setSelectedSiteOption([])
                setInterval(5)
                setUploadFile("")
                setSelectedPostEventOption([])
                setSelectedPreEventOption([])
            })
    };

    function handleIntervalChange(event) {
        setInterval(event.target.value);
    }

    //function handleFirstRunChange(event) {
    //    console.log(event.target);
    //    setFirstRunDateTime(event.target.value);
    //}

    return (
        <>
            <form name="CreateJobSchedule" onSubmit={handleSubmit}>

                {displayMessage && <div>{message}</div>}

                <div className="settings">
                    <label htmlFor="jobs">Job</label>
                    <Select options={jobs} onChange={setSelectedJobOption} defaultValue={selectedJobOption} />
                </div>

                <div className="settings">
                    <label htmlFor="jobs2">Depends on</label>
                    <Select options={dependsOnJobs} onChange={setSelectedDependsJobOption} className="basic-multi-select"
                        classNamePrefix="select" />
                </div>

                <div className="settings">
                    <label htmlFor="sites">Site</label>
                    <Select options={sites} onChange={setSelectedSiteOption} value={selectedSiteOption} isMulti className="basic-multi-select"
                        classNamePrefix="select" defaultOptions />
                </div>

                <div className="settings">
                    <label htmlFor="interval">Interval</label>
                    <input id="interval" value={interval} onChange={handleIntervalChange}></input>
                </div>

                <div className="settings">
                    <label htmlFor="event1">Pre events</label>
                    <Select options={preEvents} onChange={setSelectedPreEventOption} isMulti className="basic-multi-select"
                        classNamePrefix="select" />
                </div>
                <div className="settings">
                    <label htmlFor="event2">Post events</label>
                    <Select ref={selectPostEventsRef} options={postEvents} onChange={setSelectedPostEventOption} isMulti className="basic-multi-select"
                        classNamePrefix="select" />
                </div>

                <div className="settings">
                    <label htmlFor="selectFile">Upload file</label>
                    <input id="selectFile" type="file" value={uploadFile} onChange={setUploadFile} />
                </div>

                {/*<div className="settings">*/}
                {/*    <label htmlFor="firstRunDateTime">First Run</label>*/}
                {/*    */}{/*<DateTimePicker id="firstRunDateTime" onChange={setFirstRunDateTime} value={firstRunDateTime} />*/}
                {/*    */}{/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                {/*    */}{/*    <Stack spacing={3}>*/}
                {/*    */}{/*        */}{/*<DesktopDatePicker*/}
                {/*    */}{/*        */}{/*    label="Date desktop"*/}
                {/*    */}{/*        */}{/*    inputFormat="MM/dd/yyyy"*/}
                {/*    */}{/*        */}{/*    value={value}*/}
                {/*    */}{/*        */}{/*    onChange={handleChange}*/}
                {/*    */}{/*        */}{/*    renderInput={(params) => <TextField {...params} />}*/}
                {/*    */}{/*        */}{/*/>*/}
                {/*    */}{/*        */}{/*<MobileDatePicker*/}
                {/*    */}{/*        */}{/*    label="Date mobile"*/}
                {/*    */}{/*        */}{/*    inputFormat="MM/dd/yyyy"*/}
                {/*    */}{/*        */}{/*    value={value}*/}
                {/*    */}{/*        */}{/*    onChange={handleChange}*/}
                {/*    */}{/*        */}{/*    renderInput={(params) => <TextField {...params} />}*/}
                {/*    */}{/*        */}{/*/>*/}
                {/*    */}{/*        */}{/*<TimePicker*/}
                {/*    */}{/*        */}{/*    label="Time"*/}
                {/*    */}{/*        */}{/*    value={value}*/}
                {/*    */}{/*        */}{/*    onChange={handleChange}*/}
                {/*    */}{/*        */}{/*    renderInput={(params) => <TextField {...params} />}*/}
                {/*    */}{/*        */}{/*/>*/}
                {/*    */}{/*        <DateTimePicker*/}
                {/*    */}{/*            label="Date&Time picker"*/}
                {/*    */}{/*            value={firstRunDateTime}*/}
                {/*    */}{/*            onChange={setFirstRunDateTime}*/}
                {/*    */}{/*            renderInput={(params) => <TextField {...params} />}*/}
                {/*    */}{/*        />*/}
                {/*    */}{/*    </Stack>*/}
                {/*    */}{/*</LocalizationProvider>*/}

                {/*    */}{/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                {/*    */}{/*    <DatePicker value={firstRunDateTime} onChange={setFirstRunDateTime} />*/}
                {/*    */}{/*</MuiPickersUtilsProvider>*/}
                {/*    */}{/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                {/*    */}{/*    <div className="pickers">*/}
                {/*    */}{/*        <DatePicker value={selectedDate} onChange={handleDateChange} />*/}
                {/*    */}{/*        <TimePicker value={selectedDate} onChange={handleDateChange} />*/}
                {/*    */}{/*        <DateTimePicker value={selectedDate} onChange={handleDateChange} />*/}
                {/*    */}{/*    </div>*/}
                {/*    */}{/*</MuiPickersUtilsProvider>*/}

                {/*    */}{/*<Stack component="form" noValidate spacing={3}>*/}
                {/*    */}{/*    <TextField*/}
                {/*    */}{/*        id="date"*/}
                {/*    */}{/*        label=""*/}
                {/*    */}{/*        type="date"*/}
                {/*    */}{/*        defaultValue="2017-05-24"*/}
                {/*    */}{/*        value={firstRunDateTime}*/}
                {/*    */}{/*        onChange={setFirstRunDateTime}*/}
                {/*    */}{/*        sx={{ width: 220 }}*/}
                {/*    */}{/*        InputLabelProps={{*/}
                {/*    */}{/*            shrink: true,*/}
                {/*    */}{/*        }}*/}
                {/*    */}{/*    />*/}
                {/*    */}{/*    <TextField*/}
                {/*    */}{/*        id="time"*/}
                {/*    */}{/*        label=""*/}
                {/*    */}{/*        type="time"*/}
                {/*    */}{/*        defaultValue="07:30"*/}
                {/*    */}{/*        InputLabelProps={{*/}
                {/*    */}{/*            shrink: true,*/}
                {/*    */}{/*        }}*/}
                {/*    */}{/*        inputProps={{*/}
                {/*    */}{/*            step: 300, // 5 min*/}
                {/*    */}{/*        }}*/}
                {/*    */}{/*        sx={{ width: 150 }}*/}
                {/*    */}{/*    />*/}
                {/*    */}{/*    <TextField*/}
                {/*    */}{/*        id="datetime-local"*/}
                {/*    */}{/*        label=""*/}
                {/*    */}{/*        type="datetime-local"*/}
                {/*    */}{/*        defaultValue="2017-05-24T10:30"*/}
                {/*    */}{/*        value={firstRunDateTime}*/}
                {/*    */}{/*        onChange={setFirstRunDateTime}*/}
                {/*    */}{/*        sx={{ width: 250 }}*/}
                {/*    */}{/*        InputLabelProps={{*/}
                {/*    */}{/*            shrink: true,*/}
                {/*    */}{/*        }}*/}
                {/*    */}{/*    />*/}
                {/*    */}{/*</Stack>*/}

                {/*    <MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                {/*        <DatePicker value={selectedDate} onChange={handleDateChange} />*/}
                {/*        <TimePicker value={selectedDate} onChange={handleDateChange} />*/}
                {/*        <DateTimePicker value={selectedDate} onChange={handleDateChange} />*/}
                {/*    </MuiPickersUtilsProvider>*/}

                {/*</div>*/}

                {/*working*/}
                <div className="settings">
                    <label htmlFor="firstRunDateTime">First Run</label>
                    {/*<DateTimePicker id="firstRunDateTime" onChange={setFirstRunDateTime} value={firstRunDateTime} />*/}

                    <DatePicker showTimeSelect selected={firstRunDateTime} onChange={(date) => setFirstRunDateTime(date)} />


                </div>
                <div className="settings">
                    <label htmlFor="lastRunDateTime">Last Run</label>
                    <DateTimePicker id="lastRunDateTime" onChange={setLastRunDateTime} value={lastRunDateTime} />
                </div>
                <div className="settings">
                    <label htmlFor="startRunTime">Start time</label>
                    <DateTimePicker id="startRunTime" onChange={setStartRunTime} value={startRunTime} />
                </div>
                <div className="settings">
                    <label htmlFor="endRunTime">End Time</label>
                    <DateTimePicker id="endRunTime" onChange={setEndRunTime} value={endRunTime} />
                </div>




                <div className="settings">
                    <label></label>
                    <button type="submit">Add Schedule</button>
                </div>
            </form>
        </>
    );
}
