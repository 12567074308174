/*import { Button } from "@material-ui/core"*/
import React from "react"
import { useState } from "react";
import useFetch from "./useFetch.js"

export default function RecipientAdd(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    const { post } = useFetch(process.env.REACT_APP_API_ENDPOINT)
        
    function handleFormSubmit(e) {
        e.preventDefault();

        post("Recipients/Create", JSON.stringify({
            Name: name,
            Email: email
        }))
            .then(data => {
                console.log(data);
                //props.onTableChanged();
                /*props.onTableChange();*/
                setName("");
                setEmail("");
            })

            .catch(error => { console.log(error) })
            .finally(() => {
                props.onTableChange();
            });
    }

    return (
        <>
            <form name="RecipienAdd" onSubmit={handleFormSubmit}>
                <div>
                    <label htmlFor="name">Name</label>
                    <input name="name" value={name} onChange={event => { setName(event.target.value) }}></input>
                </div>
                <div>
                    <label htmlFor="email">Email</label>
                    <input name="email" value={email} onChange={event => { setEmail(event.target.value) }}></input>
                </div>
                <div><button type="submit">Add Recipient</button></div>
            </form>

        </>
    );
}
