import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./reducers/_store";
/*import { createRoot } from 'react-dom/client';*/



const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
console.log("baseUrl = " + baseUrl)

//if (process.env.REACT_APP_ENVIRONMENT != "local")
//{
//    baseUrl = process.env.REACT_APP_HOMEPAGE;
//}

const container = document.getElementById('root');
/*const store = createStore(todos, ['Use Redux'])*/

//function todos(state = [], action) {
//    switch (action.type) {
//        case 'ADD_TODO':
//            return state.concat([action.text])
//        default:
//            return state
//    }
//}


//const root = createRoot(container); // createRoot(container!) if you use TypeScript

//root.render(
  
//    <Provider store={store}>
//        <BrowserRouter basename={baseUrl}>
//            <App />
//        </BrowserRouter>
//    </Provider >
//  );


ReactDOM.render(

    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </Provider >,
    container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();






//const Root = () => (
//    <Provider store={store}>
//        <App />
//    </Provider >
//);

//export default Root;






