////import { TableCell } from "@material-ui/core"
////import { TableRow } from "@material-ui/core"
////import { TableBody } from "@material-ui/core"
////import { TableContainer, TableHead } from "@mui/material"
import React, { useState } from "react"
import { Label} from "reactstrap"
import useFetch from "./useFetch.js"

export default function SiteAdd(props) {
    const [siteId, setSiteId] = useState("")
    const [site, setSite] = useState("")
    const { post } = useFetch(process.env.REACT_APP_API_ENDPOINT);

    function handleSubmit(e) {
        e.preventDefault();

        post("Sites/Create", JSON.stringify({ SiteId: siteId, Site: site }))
            .then(data => { console.log(data) })
            .catch(error => { console.log(error) })
            .finally(() => {
                setSite("");
                setSiteId("");
                props.onTableChanged(true);
            })


    }

    return (
        <>
            <form name="CreateSite" onSubmit={handleSubmit}>
                <div>
                    <Label htmlFor="siteId">SiteId</Label>
                    <input name="siteId" value={siteId} onChange={event => { setSiteId(event.target.value) }}></input>
                </div>
                <div>
                    <Label htmlFor="site">Site</Label>
                    <input name="site" value={site} onChange={event => { setSite(event.target.value) }}></input>
                </div>
                <button type="submit">Add Site</button>
            </form>
        </>
    )
}