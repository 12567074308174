import React, { useState, useEffect } from 'react';
import useFetch from "./useFetch.js"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
/*import Paper from '@mui/material/Paper';*/
import JobAdd from "./JobAdd.js"
/*import { Button } from '@material-ui/core';*/
import { Button } from 'reactstrap';

export default function Job() {
    const [jobs, setJobs] = useState([])
    const { get } = useFetch(process.env.REACT_APP_API_ENDPOINT)
    const fetchURL = process.env.REACT_APP_API_ENDPOINT;
    const [tableChanged, setTableChanged] = useState(false);

    useEffect(() => {
        get("Jobs")
            .then(data => {
                setJobs(data);
                console.log(data);
            });
    }, [tableChanged])

    function handleTableChanged() {
        setTableChanged(tableChanged => !tableChanged);
    }

    function handleDeleteButtonClick(id) {
        fetch(`${fetchURL}Jobs/Delete/${id}`, { method: "DELETE" })
            .then(data => {
                console.log(data)
                setTableChanged(true);
            })
            .catch(error => {console.log(error)})
    }

    return (
        <>
            <div className="job-page">
                <JobAdd onTableChanged={handleTableChanged} />
                <TableContainer>
                    <Table align="left" sx={{ minWidth: 300, maxwi: 300 }} aria-label="simple-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Job Name</TableCell>
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {jobs.map((row) => (
                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left" component="th" scope="row">
                                        {row.jobName}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() =>{handleDeleteButtonClick(row.id)}}>Delete
                                            </Button>
                                        </TableCell>
                                </TableRow>

                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </div>
        </>
    );
}
