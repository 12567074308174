import React, { useState, useEffect } from 'react';
import useFetch from "./useFetch.js"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from 'reactstrap';
import SiteAdd from "./SiteAdd.js"

export default function Site() {
    const [sites, setSites] = useState([])
    const [tableChanged, setTableChanged] = useState(false);
    const { get } = useFetch(process.env.REACT_APP_API_ENDPOINT)
    const fetchURL = process.env.REACT_APP_API_ENDPOINT;


 
    function createHeader(title) {
        return { title };
    }

    function handleDeleteClick(id) {
        fetch(`${fetchURL}Sites/Delete/${id}`, { method: "DELETE" })
            .then(data => {
                console.log(data)
                handleTableChanged()
                /*setTableChanged(true);*/
            })
            .catch(error => {console.log(error)})

    }


    useEffect(() => {
            get("Sites")
            .then(data => {
                /*setSites(data && data.map((site) => ({ value: site.id, label: site.site1 })))*/
                setSites(data);
                data.sort();
                console.log(data)
            })
            .catch(error => { console.log(error) })
    }, [tableChanged])

    const headers = [createHeader("SiteId"),
    createHeader("Site")
    ]

    function handleTableChanged() {
        /*setTableChanged(true);*/
        setTableChanged(tableChanged => !tableChanged);
    }

    return (
        <div>
            {/*<BasicTable headers={headers} rows={rows}></BasicTable>*/}
            <SiteAdd onTableChanged={handleTableChanged}/>
            <TableContainer component={Paper}>
                <Table align="center" sx={{ minWidth: 650, maxWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/*{headers.map((header) => (*/}
                            {/*    <TableCell align="right">{header.title}</TableCell>*/}
                            {/*))}*/}
                            <TableCell align="right">Site Id</TableCell>
                            <TableCell align="right">Site</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sites.map((row) => (
                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="right" component="th" scope="row">
                                    {row.siteId}
                                </TableCell>
                                <TableCell align="right">{row.site1}</TableCell>
                                <TableCell><Button onClick={() => {handleDeleteClick(row.id)}}>Delete</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
}
