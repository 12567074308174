import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle';
import JobScheduleAdd from './JobScheduleAdd.js'
import useFetch from "./useFetch.js"
import CollapsibleTable from './CollapsibleTable.js';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ColourToggleButton from "./Toggle/ColourToggleButton.js"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { Label } from 'reactstrap';

/*const { env } = require('process');*/


export default function Home() {
    const [jobSchedules, setJobSchedules] = useState([]);
    const [tableChanged, setTableChanged] = useState(false);
    const [showAddSchedule, setShowAddSchedule] = useState(false)
    const { get, post } = useFetch(process.env.REACT_APP_API_ENDPOINT);
    //const defaultInitial = new Date().setTime(-1 * 60 * 60 * 1000)
    //const defaultFinal = new Date(0 * 60 * 60 * 1000)
    const [excludeInitialTime, setExcludeInitialTime] = useState(new Date());
    const [excludeFinalTime, setExcludeFinalTime] = useState(new Date());
    const fetchURL = process.env.REACT_APP_API_ENDPOINT;
    /*const environment = process.env.NODE_ENV*/
    const customEnvironment = process.env.REACT_APP_ENVIRONMENT

    /*main table*/
    useEffect(() => {
        console.log("fetchURL =" + fetchURL);
        /*console.log("environment =" + environment);*/
        console.log("customEnvironment =" + customEnvironment);

        fetch(fetchURL+"JobSchedules")
        /*fetch(process.env.REACT_APP_API_URL +"JobSchedules")*/
            .then(res => res.json())
            .then(data => {
                setJobSchedules(data);
                console.log(data);
            })
            .catch(error => { console.log(error) })
            .finally(() => {
                setTableChanged();
            });
    }, [tableChanged]);

    /*actually runs the sync job runs on page load or page refresh*/
    useEffect(() => {
        /*set global exclusion times*/
        get("GlobalAppSettings")
            .then(data => {

                const initialTime = new Date(data.initialExclusionTime)
                console.log("data.initialExclusionTime.toLocaleTimeString() = " + initialTime)
                if (data.initialExclusionTime != null) {
                    setExcludeInitialTime(initialTime)
                }
                else {
                    setExcludeInitialTime(new Date().setTime(-1 * 60 * 60 * 1000))
                }

                const finalTime = new Date(data.finalExclusionTime)

                console.log("data.finalExclusionTime.toLocaleTimeString() = " + finalTime)
                if (finalTime != null) {
                    setExcludeFinalTime(finalTime)
                }
                console.log(data);
            })
            .catch(error => { console.log(error) })

        //run only once outside the interval
        get("JobSchedules/ExecuteJobs")
            .then(data => {
                console.log(data)
                handleTableChanged();
            });

        setInterval(() => {
            /*    get("JobSchedules/ExecuteJobs")*/
            // .then(data => { console.log(data) })
            // .catch(error => {console.log(error)})
            console.log("Sync Job check run")
        }, 60000)
    }, []);

    function toggleShowAddSchedule() {
        setShowAddSchedule(!showAddSchedule)
    }

    function handleTableChanged() {
        setTableChanged(tableChanged => !tableChanged)
    }

    function handleExcludeInitialTimeChange(date) {
        const localeDateTime = date.toLocaleTimeString();
        setExcludeInitialTime(date);
        post("GlobalAppSettings/UpdateInitialTime", JSON.stringify({ InitialExclusionTime: localeDateTime }))
            .then(data => {
                console.log(data)
            })
            .catch(error => { console.log(error) })
    }

    function handleExcludeFinalTimeChange(date) {
        setExcludeFinalTime(date);
        const localDateTime = date.toLocaleTimeString();

        console.log("final localDateTime = " + localDateTime);
        post("GlobalAppSettings/UpdateFinalTime", JSON.stringify({ FinalExclusionTime: localDateTime }))
            .then(data => (console.log(data)))
            .catch(error => { console.log(error) })
    }


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                <div id="globalSettingsGroup">
                    <ColourToggleButton />
                    <p>Prevent all jobs running between these times to stop interference with the MRP Job</p>

                    <div className="settings">
                        <label htmlFor="excludeInitialTime">Intital Exclusion time</label>
                        <DatePicker id="excludeInitialTime"
                            selected={excludeInitialTime}
                            onChange={(date) => handleExcludeInitialTimeChange(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            isClearable
                        />
                    </div>

                    <div className="settings">
                        <label htmlFor="excludeFinalTime">Final Exclusion time</label>
                        <DatePicker id="excludeFinalTime"
                            selected={excludeFinalTime}
                            onChange={(date) => handleExcludeFinalTimeChange(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            isClearable
                        />
                    </div>
                </div>
                <p align="left">
                    <button align="left" onClick={toggleShowAddSchedule}>Display/hide add schedule</button>
                </p>

                {'\n'} {/*line break*/}

                {showAddSchedule && <JobScheduleAdd onTableChanged={handleTableChanged} tableChanged={tableChanged} ></JobScheduleAdd>}

                {/*<JobScheduleTable jobSchedules={jobSchedules}></JobScheduleTable>*/}

                <CollapsibleTable jobSchedules={jobSchedules} onTableChanged={handleTableChanged} tableChanged={tableChanged} />

                <div className="Sync Job table">

                </div>

            </div>
        </MuiPickersUtilsProvider>
    );

}
