import React from "react"
import { useState } from "react";
import useFetch from "./useFetch.js"


export default function JobAdd(props) {

    const { post } = useFetch(process.env.REACT_APP_API_ENDPOINT)
    const [jobName, setJobName] = useState("");

    function handleSubmit(e){
        e.preventDefault();

        post("Jobs/Create", JSON.stringify({ JobName: jobName }))
            .then(data => { console.log(data) })
            .catch(error => { console.log(error) })
            .finally(() => {
                props.onTableChanged(true)
                setJobName("");
            }
            );
    }

    return (
        <>
            <form name="CreateJob" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="jobName">Job Name</label>
                    <input id="jobName" value={jobName} onChange={event => { setJobName(event.target.value)}}></input>
                </div>
                <div>
                    <button type="submit">Add Job</button>
                    </div>
            </form>
            
        </>
    )
}
