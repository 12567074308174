import { stringify } from "query-string";
import React, { useState, useEffect } from 'react';

// Static oidc params for a single provider
/*const redirect_uri = "http://localhost:3000/auth";*/
const authority = "https://hermanmiller.onelogin.com/oidc/2";

//local
//const client_id = "5344e740-ceec-013a-a6d2-0286d1a1cf3318380";
//const redirect_uri = "http://localhost:44456/auth";

//dev
//const client_id = "dd5d7b60-e035-013a-bbe3-0a4e3c7c6f6318380";
//const redirect_uri = "https://hmeuronetdev.hermanmiller.com/syncjobmanager/auth";


//this is what should work for all env deployments but doesn't yet
//const client_id = {process.env.REACT_APP_CLIENT_ID};
//const redirect_uri = { process.env.REACT_APP_REDIRECT_URI };

const { REACT_APP_CLIENT_ID, REACT_APP_REDIRECT_URI } = process.env;

const client_id = REACT_APP_CLIENT_ID;
const redirect_uri = REACT_APP_REDIRECT_URI;


const response_type = "id_token token";
const scope = "openid profile";

export const beginAuth = ({ state, nonce }) => {



  // Generate authentication URL
  const params = stringify({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    nonce
  });


  const authUrl = `${authority}/auth?${params}`;
    console.log("authUrl = " + authUrl);

  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);
};
