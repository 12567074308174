import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
//import Checkbox from '@mui/material/Checkbox';

//import { alpha } from '@mui/material/styles';
//import TablePagination from '@mui/material/TablePagination';
//import TableSortLabel from '@mui/material/TableSortLabel';
//import Toolbar from '@mui/material/Toolbar';
//import Tooltip from '@mui/material/Tooltip';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Switch from '@mui/material/Switch';
//import DeleteIcon from '@mui/icons-material/Delete';
//import FilterListIcon from '@mui/icons-material/FilterList';
//import { visuallyHidden } from '@mui/utils';
import { Button } from 'reactstrap';

/*import useFetch from "./useFetch.js"*/

export default function CollapsibleTable(props) {

    /*const { post } = useFetch("http://localhost:5107/api/")*/
    /*const { get, post } = useFetch(process.env.REACT_APP_API_ENDPOINT)*/
    const fetchURL = process.env.REACT_APP_API_ENDPOINT;

    function concatenateSites(props) {
        let forEachData = '';
        props.forEach(sites => forEachData += sites.siteName + ',');
        return (
            <TableCell align="right">{forEachData}</TableCell>
        );
    }

    function formatDateTime(props) {
        const date = new Date(props)
        console.log(props)

        const dateExistsCell = <TableCell align="right">{new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: 'numeric',
            minute: 'numeric'
        }).format(date)}</TableCell>

        const dateNullCell = <TableCell align="right"></TableCell>

        if (props) {
            return dateExistsCell
        }
        else {
            return dateNullCell
        }

    }

    function formatDuration(props) {
        var seconds = ((props % 60000) / 1000).toFixed(0);
        var outPut = "";

        if (seconds < 1) {
            outPut = "< 1 second";
        }
        else {
            if (seconds === 1) {
                outPut = seconds + " second";
            }
            else if (seconds < 60) {
                outPut = seconds + " seconds";
            }
            else if (seconds >= 60) {
                outPut = Math.floor(props / 60000) + " minutes";
            }
        }

        return <TableCell align="right">{outPut}</TableCell>

    }


    function formatTime(props) {
        const date = new Date(props)
        console.log(props)

        const dateExistsCell = <TableCell align="right">{new Intl.DateTimeFormat("en-GB", {
            hour: 'numeric',
            minute: 'numeric'
        }).format(date)}</TableCell>

        const dateNullCell = <TableCell align="right"></TableCell>

        if (props) {
            return dateExistsCell
        }
        else {
            return dateNullCell
        }

    }

    function handleDeleteButtonClick(jobScheduleId) {
        /*const jobScheduleId = event.target*/
        /*const jobScheduleId = event.id;*/
        console.log("jobScheduleId to delete = " + jobScheduleId)

        fetch(`${fetchURL}JobSchedules/Delete/${jobScheduleId}`, { method: 'DELETE' })
            .then(data => {
                props.onTableChanged();
                console.log(data);
            })
            .catch(error => { console.log(error) })
    }
    

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    {/*<TableCell padding="checkbox">*/}
                    {/*    <Checkbox*/}
                    {/*        color="primary"*/}
                    {/*        indeterminate={numSelected > 0 && numSelected < rowCount}*/}
                    {/*        checked={rowCount > 0 && numSelected === rowCount}*/}
                    {/*        onChange={onSelectAllClick}*/}
                    {/*        inputProps={{*/}
                    {/*            'aria-label': 'select all desserts',*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</TableCell>*/}
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        {row.jobName}
                    </TableCell>
                    <TableCell align="right">{row.interval}</TableCell>
                    <TableCell align="right">{row.dependsOnJobName}</TableCell>
                    <TableCell align="right">{row.lastRunStatus}</TableCell>
                    {formatDuration(row.lastRunDuration)}
                    <TableCell align="right">{row.lastRunDetail}</TableCell>
                    <TableCell align="right">{row.uploadFile}</TableCell>
                    {formatDateTime(row.firstRunDateTime)}
                    {formatDateTime(row.lastRunDateTime)}
                    {formatTime(row.startTime)}
                    {formatTime(row.endTime)}
                    {concatenateSites(row.jobScheduleSites)}
                    <TableCell align="right"><Button onClick={()=>handleDeleteButtonClick(row.id)}>Delete</Button></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Detail
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">LastRun</TableCell>
                                            <TableCell align="right">LastRunStatus</TableCell>
                                            <TableCell align="right">LastRunDuration</TableCell>
                                            <TableCell align="right">LastRunDetail</TableCell>
                                            <TableCell align="right">Site</TableCell>
                                            <TableCell align="right">Error Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.jobScheduleSites.map((siteInfoRow) => (
                                            <TableRow key={siteInfoRow.id}>
                                                {/*<TableCell align="right">{siteInfoRow.lastRun}</TableCell>*/}
                                                {/*{formatDateTime(siteInfoRow.lastRun)}*/}
                                                {formatDateTime(siteInfoRow.lastRun)}
                                                <TableCell align="right">{siteInfoRow.lastRunStatus}</TableCell>
                                                {/*<TableCell align="right">{siteInfoRow.lastRunDuration}</TableCell>*/}
                                                {formatDuration(siteInfoRow.lastRunDuration)}
                                                <TableCell align="right">{siteInfoRow.lastRunDetail}</TableCell>
                                                <TableCell component="th" scope="row" align="right">
                                                    {siteInfoRow.siteName}
                                                </TableCell>
                                                <TableCell>{siteInfoRow.errorDetail} </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }



    return (
        <TableContainer component={Paper}>
            {/*<Table stickyHeader aria-label="collapsible table">*/}
                <Table aria-label="collapsible table">
                <TableHead>
                     <TableRow>
                        <TableCell />
                        <TableCell align="right">Job</TableCell>
                        <TableCell align="right">Interval</TableCell>
                        <TableCell align="right">DependsOn</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Duration</TableCell>
                        <TableCell align="right">Detail</TableCell>
                        <TableCell align="right">UploadFile</TableCell>
                        <TableCell align="right">Start Date</TableCell>
                        <TableCell align="right">End Date</TableCell>
                        <TableCell align="right">Start Time</TableCell>
                        <TableCell align="right">End Time</TableCell>
                        <TableCell align="right">Site</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.jobSchedules.map((row) => (
                        <Row key={row.id} row={row} />
                    ))}
                </TableBody>
            </Table>
            {/*<TablePagination*/}
            {/*    rowsPerPageOptions={[5, 10, 25]}*/}
            {/*    component="div"*/}
            {/*    count={rows.length}*/}
            {/*    rowsPerPage={rowsPerPage}*/}
            {/*    page={page}*/}
            {/*    onPageChange={handleChangePage}*/}
            {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*/>*/}
        </TableContainer>

    );
}





