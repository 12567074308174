//import React from 'react';
//import { Route } from 'react-router';
//import Layout from './components/Layout.js';
//import Home  from './components/Home.js';


//import './custom.css'

//export default function App() {
//  const displayName = "Sync Job Management";

//    return (
//      <Layout>
//        <Route exact path='/' component={Home} />
//        {/*<Route path='/counter' component={Counter} />*/}
//        {/*<Route path='/fetch-data' component={FetchData} />*/}
//      </Layout>
//    );

//}


import React from "react";
import { connect } from "react-redux";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import Header  from "./components/Auth/Header";
import LoginPage from "./components/Auth/LoginPage";
import AuthPage from "./components/Auth/AuthPage";
import ProfilePage from "./components/Auth/ProfilePage";
import PublicPage from "./components/Auth/PublicPage";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import { CLEAR_ALL } from "./util/actionTypes";

import Home from "./components/Home"
import Job from "./components/Job"
import Site from "./components/Site"
import Recipient from "./components/Recipient"

//import dotenv from "dotenv"
//import express from "express"

//dotenv.config()

//const app = express()
//const port = process.env.PORT || 5000

import "./css/App.css";
import { light } from "@material-ui/core/styles/createPalette";

const { REACT_APP_HOMEPAGE, PUBLIC_URL } = process.env;
//const homeUri = process.env.REACT_APP_HOMEPAGE + "home";
//const loginUri = process.env.REACT_APP_HOMEPAGE + "login";
//const authUri = process.env.REACT_APP_HOMEPAGE + "auth";


//const homeUri = PUBLIC_URL + "/home";
//const loginUri = PUBLIC_URL + "/login";
//const authUri = PUBLIC_URL + "/auth";

const homeUri = "/home";
const loginUri = "/login";
const authUri = "/auth";

//const devLoginUri = "/syncjobmanager/login";
//const devAuthUri = "/syncjobmanager/auth";
//const devHomeUri = "syncjobmanager/home"




//const loginUri = process.env.REACT_APP_HOMEPAGE +  "/login";
//const authUri = process.env.REACT_APP_HOMEPAGE +  "/auth";
//const homeUri = process.env.REACT_APP_HOMEPAGE +  "home";

//const loginUri = devLoginUri;
//const authUri = devAuthUri;
//const homeUri = devHomeUri;


const App = ({ loggedIn, logout }) => (


    <Router>
        <Header loggedIn={loggedIn} logout={logout} />
        <div className="App-page">
            <Switch>

                <ProtectedRoute
                    component={LoginPage}
                    open={!loggedIn}
                    path={loginUri}
                    redirect={homeUri}
                />


                <ProtectedRoute
                    component={Home}
                    open={loggedIn}
                    path={homeUri}
                    redirect={loginUri}
                />

                <ProtectedRoute
                    component={Job}
                    open={loggedIn}
                    path="/job"
                    redirect={loginUri}
                />

                <ProtectedRoute
                    component={Site}
                    open={loggedIn}
                    path="/site"
                    redirect={loginUri}
                />

                <ProtectedRoute
                    component={Recipient}
                    open={loggedIn}
                    path="/recipient"
                    redirect={loginUri}
                />


                <Route
                    component={AuthPage}
                    path={authUri}
                />
                <Route
                    render={PublicPage}
                    path="/public"
                />
                <Route path="/">
                    <Redirect to={homeUri} />
                </Route>
                      
            </Switch>
        </div>
    </Router>
);

const mapStateToProps = state => ({
    loggedIn: !!state.token.accessToken
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch({ type: CLEAR_ALL })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
