import React, { useState, useEffect } from 'react';
//import BasicTable from "./BasicTable.js"
//import DenseTable from './DenseTable.js';
import useFetch from "./useFetch.js"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from 'reactstrap';
import RecipientAdd from "./RecipientAdd.js"
import Styles from '../custom.css'

export default function Recipient(props) {
    const [recipients, setRecipients] = useState([])
    const [tableChanged, setTableChanged] = useState(false);
    const { get } = useFetch(process.env.REACT_APP_API_ENDPOINT)
    const fetchURL = process.env.REACT_APP_API_ENDPOINT;

    function createData(name, email) {
        return { name, email };
    }
    function createHeader(title) {
        return { title };
    }

    useEffect(() => {
        get("recipients")
            .then(data => {
                setRecipients(data)
                console.log(data)
            })
    }, [tableChanged])


    function handleTableChange() {
        setTableChanged(tableChanged => !tableChanged);
    }

    //const rows = [
    //    createData('Gareth', "grajb2@hermanmiller.com"),
    //    createData('Steph', "steph@hermanmiller.com"),
    //    createData('Karthick', "karthick@hermanmiller.com"),
    //    createData('Basu', "basu@hermanmiller.com"),
    //];

    const headers = [createHeader("Name"),
                    createHeader("Email")
    ]

    function handleDeleteClick(id)
    {
        fetch(`${fetchURL}Recipients/Delete/${id}`, { method: "DELETE" })
            .then(data => {
                console.log(data)
                handleTableChange();
               })
            .catch(error => {console.log(error)})

    }


    return (
        <div className={Styles.center}>
            {/*<BasicTable headers={headers} rows={rows}></BasicTable>*/}
            <RecipientAdd onTableChange={handleTableChange}/>
            <TableContainer component={Paper} className={Styles.center}>
                {/*<Table align="left" sx={{ minWidth: 650, maxWidth: 650 }} aria-label="simple table">*/}
                <Table >
                    <TableHead>
                        <TableRow>
                            {/*{headers.map((header) => (*/}
                            {/*    <TableCell align="right">{header.title}</TableCell>*/}
                            {/*))}*/}
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Email</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recipients.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="right" component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.email}</TableCell>
                                <TableCell><Button onClick={() => {handleDeleteClick(row.id)}}>Delete</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
}
