import React, {useEffect, useState} from "react"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useFetch from "../useFetch.js"

export default function ColorToggleButton(props) {
    const { get, post } = useFetch(process.env.REACT_APP_API_ENDPOINT)
    const [alignment, setAlignment] = useState('allJobsEnabled');

    const handleChange = (event, newAlignment) => {
        console.log("newAlignment = " + newAlignment)
        console.log(JSON.stringify({ EnableAllJobs: newAlignment }))

        console.log(process.env.REACT_APP_API_ENDPOINT + "GlobalAppSettings/EnableAllJobs")

        setAlignment(newAlignment);
        post("GlobalAppSettings/EnableAllJobs", JSON.stringify({ EnableAllJobs: newAlignment }))
            .then(data => { console.log(data) })
            .catch(error => {console.log(error)})
    };

    useEffect(() => {
        get("GlobalAppSettings")
            .then(data => {
                console.log("toggle = " + data.allJobsEnabled)

                if (data.allJobsEnabled === true) {
                    setAlignment("allJobsEnabled")
                }
                else {
                    setAlignment("allJobsDisabled")
                }

                console.log(data);
            })
            .catch(error => { console.log(error) })

    },[])

    return (
        <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton value="allJobsEnabled">All Jobs Enabled</ToggleButton>
            <ToggleButton value="allJobsDisabled">All Jobs Disabled</ToggleButton>
        </ToggleButtonGroup>
    );
}
